import { Dayjs } from 'dayjs'
import { useOpcontractsResource } from '~/modules/options/api/useOpcontractsResource'
import dayAPI from '~/utils/dayAPI'
import { getOptionIntradayEndDate, getOptionContractOpenDate } from './getOptionContractDateTime'

/** 給出選擇權 `近期契約代號`、`熱門契約代號`、`契約對應結算日` */
export const useGetOptionContract = (intradayEndTime: Dayjs) => {
  const getContract = useOpcontractsResource({
    from: getOptionContractOpenDate().clone(),
    to: intradayEndTime.clone(),
  }).res.data

  return {
    default: getContract?.default,
    contractMonth: getContract?.contractMonth,
    settlementDate: getContract?.settlementDate,
  }
}

/** 選擇權契代號約轉週小台代號 (依賴選擇權合約) `202303W1` => `MX1-2303` */
export const getWeeklyMxfContract = (optionContract: string) => {
  if (typeof optionContract !== 'string' || optionContract.length !== 8) {
    // 月選 合約代號沒有Ｗ, 長度固定6碼 yyyymm
    return 'MTX-1'
  }

  // 周選 合約代號有Ｗ, 長度固定8碼 yyyymmWw
  const yearAndMonth = optionContract.slice(2, 6)
  const week = optionContract.slice(-1)
  return 'MX' + week + '-' + yearAndMonth
}

/** 預設的選擇權合約 (依據現在時間 給出對應最熱門交易合約 處理W1~W5) */
// export const getOptionContract = () => {
//   const date = dayAPI()
//   const hour = date.hour()

//   const settlementDateArray = getSettlementDate().map(s => dayAPI(s).startOf('day').add(15, 'hour'))

//   const w1Contract = settlementDateArray[0]
//   const w2Contract = settlementDateArray[1]
//   const w3Contract = settlementDateArray[2]
//   const w4Contract = settlementDateArray[3]
//   const w5Contract = settlementDateArray[4] ?? null

//   /** 判斷現在日期夾在哪兩個結算日之間 並給出契約代號 */
//   const contract = () => {
//     /** W1 ~ W2 */
//     if (date > w1Contract && date < w2Contract) {
//       return w2Contract.format('YYYYMMW2')
//     }
//     /** W2 ~ W3 */
//     if (date > w2Contract && date < w3Contract) {
//       return w3Contract.format('YYYYMM')
//     }
//     /** W3 ~ W4 */
//     if (date > w3Contract && date < w4Contract) {
//       return w4Contract.format('YYYYMMW4')
//     }

//     /** 如果有W5選擇權 */ // add(1,'week')也能正確的跨年份
//     if (settlementDateArray.length > 4) {
//       /** W4 ~ W5 */
//       if (date > w4Contract && date < w5Contract) {
//         return w5Contract.format('YYYYMMW5')
//       }
//       /** W5 ~ */
//       if (date > w5Contract) {
//         return w5Contract.add(1, 'week').format('YYYYMMW1')
//       }
//       /** 沒有W5選擇權 直接進下個月W1 */
//     } else if (date > w4Contract) {
//       /** W4 ~ */
//       return w4Contract.add(1, 'week').format('YYYYMMW1')
//     }
//     /** W1 ~ W2 */
//     if (date <= w1Contract) {
//       return w1Contract.format('YYYYMMW1')
//     }
//   }

//   console.log(
//     '預設合約:',
//     contract(),
//     settlementDateArray.map(s => dayAPI(s).format('YYYY/MM/DD')),
//   )

//   return contract()
// }

/** 給出週小台契約代碼 */
// export const getWeeklyMxfContract = () => {
//   const date = dayAPI()

//   const settlementDateArray = getSettlementDate().map(s => dayAPI(s).startOf('day').add(15, 'hour'))

//   const w1Contract = settlementDateArray[0]
//   const w2Contract = settlementDateArray[1]
//   const w3Contract = settlementDateArray[2]
//   const w4Contract = settlementDateArray[3]
//   const w5Contract = settlementDateArray[4] ?? null

//   /** 判斷現在日期夾在哪兩個結算日之間 並給出契約代號 */
//   const contract = () => {
//     /** W1 ~ W2 */
//     if (date > w1Contract && date < w2Contract) {
//       return w2Contract.format('X2-YYMM')
//     }
//     /** W2 ~ W3 */
//     if (date > w2Contract && date < w3Contract) {
//       return w3Contract.format('TX-1')
//     }
//     /** W3 ~ W4 */
//     if (date > w3Contract && date < w4Contract) {
//       return w4Contract.format('X4-YYMM')
//     }

//     /** 如果有W5選擇權 */ // add(1,'week')也能正確的跨年份
//     if (settlementDateArray.length > 4) {
//       /** W4 ~ W5 */
//       if (date > w4Contract && date < w5Contract) {
//         return w5Contract.format('X5-YYMM')
//       }
//       /** W5 ~ */
//       if (date > w5Contract) {
//         return w5Contract.add(1, 'week').format('X1-YYMM')
//       }
//       /** 沒有W5選擇權 直接進下個月W1 */
//     } else if (date > w4Contract) {
//       /** W4 ~ */
//       return w4Contract.add(1, 'week').format('X1-YYMM')
//     }
//     /** W1 ~ W2 */
//     if (date <= w1Contract) {
//       return w2Contract.format('X1-YYMM')
//     }
//   }

//   return 'M' + contract()
// }

/** 給出當月所有的週三日期 */
// const getSettlementDate = () => {
//   const result = []

//   const date = dayAPI()
//   const month = date.month()
//   const year = date.year()

//   const getDate = new Date(year, month, 1)
//   while (getDate.getMonth() === month) {
//     if (getDate.getDay() === 3) {
//       result.push(new Date(getDate.getTime()))
//     }
//     getDate.setDate(getDate.getDate() + 1)
//   }
//   return result
// }

/** 預設的選擇權合約主要熱絡交易起始日 */
// export const getOptionContractOpenDate = () => {
//   const date = dayAPI()

//   const settlementDateArray = getSettlementDate().map(s => dayAPI(s))

//   const w1Contract = settlementDateArray[0]
//   const w2Contract = settlementDateArray[1]
//   const w3Contract = settlementDateArray[2]
//   const w4Contract = settlementDateArray[3]
//   const w5Contract = settlementDateArray[4] ?? null

//   const contractOpenDate = () => {
//     if (date > w1Contract && date < w2Contract) {
//       return w1Contract
//     }
//     if (date > w2Contract && date < w3Contract) {
//       return w2Contract
//     }
//     if (date > w3Contract && date < w4Contract) {
//       return w3Contract
//     }

//     if (settlementDateArray.length > 4) {
//       if (date > w4Contract && date < w5Contract) {
//         return w4Contract
//       }
//     } else if (date > w4Contract) {
//       return w4Contract
//     }
//   }

//   return contractOpenDate()?.startOf('day')
// }
