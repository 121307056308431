import { map } from 'lodash'
import { useMemo, useState } from 'react'
import { AnyObject, WithNonNilKeys } from 'tsdef'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { expectType } from '~/utils/tsd'
import { __TYPING__ } from '~/utils/__TYPING__'

export const signalrHooks2 = {
  /**
   * - 慢速拿 signalr 的 values
   * - 慢速觸發 re-render
   */
  useValuesThrottled(throttleMs = 1000) {
    const [values, setValues] = useState(signalrStore2.values)

    useIntervalNow(() => {
      setValues({ ...signalrStore2.values })
    }, throttleMs)

    return values
  },

  /** 給予 string[] 返回一組報價陣列 */
  useQuotes(symbols: string[] = []) {
    const quotes = signalrHooks2.useValuesThrottled(1000).quote

    return map(symbols, symbol => quotes[symbol]).filter(value => !!value) as Signalr.ValueOfOHLC[]
  },

  /**
   * 合併你給的其它數據，合併到報價數據
   *
   * @example
   *   // ## 例如你可以用訊號牆的數據，合併出報價數據
   *
   *   const data = signalrHooks2.useMergeQuotes([{ symbol: 'TX-1', message: '盤中創高' }])
   *   // 你的數據
   *   data[0].symbol // 'TX-1'
   *   data[0].message // '盤中創高'
   *
   *   // ...報價數據
   *   data[0].close // 18169
   *   data[0].low // 18000
   */
  useMergeQuotes<
    Data extends AnyObject[] = WithNonNilKeys<AnyObject & { symbol: string }, 'symbol'>[],
  >(data?: Data) {
    const quotes = signalrHooks2.useValuesThrottled(1000).quote

    return useMemo(() => {
      return map(data, datum => {
        return {
          ...datum,
          ...quotes[datum.symbol],
        }
      }) as (Data[number] & Signalr.ValueOfOHLC)[]
    }, [data, quotes])
  },
}

/* istanbul ignore next */
/* eslint-disable @typescript-eslint/ban-ts-comment */
if (__TYPING__) {
  // [test] 可以給「含有 symbol 屬性」的「物件」
  expectType<({ message: string } & Signalr.ValueOfOHLC)[]>(
    signalrHooks2.useMergeQuotes([{ symbol: true, message: '盤中創高18169' }]),
  )

  // [test] 不能給 string[]
  expectType<Signalr.ValueOfOHLC[]>(
    /** @ts-expect-error 若亮紅線代表要修到不亮線 */
    signalrHooks2.useMergeQuotes(['TX-1']),
  )
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
