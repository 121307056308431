import { Dayjs } from 'dayjs'
import { proxy } from 'valtio'
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2'

export type Asia71178Score = {
  date: string
  score: number
}

export const asia71178_store = proxy({
  scores: [] as Asia71178Score[],
  buildUp: NaN as number,
  buildDn: NaN as number,
  powerValue: NaN as number,
  tabs1: new FuiTabs2(['default', 'watchList']),
  optionVolumeSort: 'default' as 'default' | 'call' | 'put' | 'total',
  pageState: 'default' as 'default' | 'option',
})
