import type { Option } from '~/modules/options/utils/Option'

export const parseOptionsContractMonthString: (
  contractMonth?: string | undefined,
) => Option.Contract | null = (
  /**
   * Give me the month of property which in API endpoint of /opcontracts, e.g. `'202011'`
   * `'202011W1'` `'202011W2'`
   */
  contractMonth?: string,
) => {
  if (!contractMonth) {
    return null
  }

  const isContractOnMonth = !contractMonth.includes('W')
  const contractMonthAt = parseInt(contractMonth.substring(4, 6))
  const contractWeekAt = contractMonth.length == 6 ? 3 : parseInt(contractMonth.charAt(7))

  return {
    /** 合約屬於週權還是月權 */
    contractType: isContractOnMonth ? 'month' : 'week',
    /** 合約屬於週, return e.g. `1` `2` `3` `4` `null` */
    contractWeekAt: contractWeekAt,
    /** 合約屬於月份, return e.g. `1` `2` ... `11` `12` */
    contractMonthAt: contractMonthAt,
  }
}
