import { Dayjs } from 'dayjs'
import { proxy } from 'valtio'
import dayAPI from '~/utils/dayAPI'
import { getOptionIntradayEndDate, getOptionContractOpenDate } from './getOptionContractDateTime'

export const optionAnalyzeStore = proxy({
  /** 目前合約 */
  currentContract: '' as string,
  /** 所有合約 */
  allContract: [''] as string[],
  /** 所有合約結算日 */
  allContractSettlementDate: { ['']: '' } as { [key: string]: string },
  /** 該合約結算日 */
  currentSettlementDate: '' as string,
  /** 預設選擇權區間開始時間 */
  contractStartDateTime: getOptionContractOpenDate() as Dayjs,
  /** 預設選擇權區間結束時間 */
  intradayEndTime: dayAPI() as Dayjs,

  /** 口數篩選 */
  filterLot: 0 as number,
  /** 金額篩選 */
  filterAmount: 0 as number,

  //useOptionQuote傳遞
  /** 價平 */
  atTheMoneyPrice: 0 as number,
  /** 價平和 */
  atTheMoneySum: 0 as number,
  /** 剩餘天數 */
  remainingDays: 0 as number,
  /** 合成期貨 */
  syntheticFutures: 0 as number,

  //客製化功能(待開發)
  /** 細部時間模式 */
  detailTimeType: false as boolean,
  /** 價格回朔模式 */
  backtrackPriceTime: false as boolean,
})

//contract
