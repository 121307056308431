import camelcaseKeys from 'camelcase-keys'
import { Dayjs } from 'dayjs'
import useSWR from 'swr-0-5-6'
import { apirc } from '~/configs/apirc'
import type { Option } from '~/modules/options/utils/Option'

export const useOpcontractsResource = (params?: {
  from?: Dayjs | undefined
  to?: Dayjs | undefined
}) => {
  const restfulApiURL = apirc.options.opcontractsUrlGet({
    from: params?.from,
    to: params?.to,
  })

  const swr = useSWR<Option.OpcontractRemoteData>(
    params?.from && params?.to ? restfulApiURL : null,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  )

  return {
    res: {
      ...swr,
      data: swr.data ? (camelcaseKeys(swr.data) as Option.OpcontractRemoteData) : null,
    },
  }
}
