import { __DEV__ } from '~/utils/__DEV__'
import { __PRODUCTION__ } from '~/utils/__PRODUCTION__'
import { __TEST__ } from '~/utils/__TEST__'

/**
 * 拿來測 typing 的
 *
 * - 預期永遠不會執行
 */
export const __TYPING__ = !__DEV__ && !__PRODUCTION__ && !__TEST__
